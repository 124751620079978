
import React from 'react'
import styled, { keyframes } from 'styled-components'

import { Link } from 'react-router-dom'
import ETH from '../../../assets/img/images/logo.png'

const FarmCards: React.FC = () => {
  return (
    <Wrapper>
      <HeaderBox>
        <ItemBox style={{ width: 85, marginLeft: 10 }} className="hide">
          POOL
        </ItemBox>

        <ItemBox style={{ width: 70 }} className="hide">
          TOKEN
        </ItemBox>

        <ItemBox style={{ width: 95 }}>
          APR/MONTH
        </ItemBox>

        {/* <ItemBox>
          REWARD <br />
          DISTRIBUTION
        </ItemBox>

        <ItemBox className="hide">
          TOTAL VALUE <br />
          LOCKED
        </ItemBox> */}

        <ItemBox>
          LOCKING
        </ItemBox>
      </HeaderBox>

      <StyledCards>
        {/* <Link to={`/stake`}>
          <ItemBoxPool>
            <ItemPool style={{ width: 15 }} className="hide">
              STAIKÃO
            </ItemPool>

            <ItemPool style={{ marginLeft: 60, width: 85 }} className="hide">
              <img src={ETH} alt="ULTRABOX" style={{ width: 37 }} /> 
            </ItemPool>

            <ItemPool style={{ marginLeft: 55 }}>
              20%
            </ItemPool>

             <ItemPool style={{ marginLeft: 68, width: 120 }}>
              11800 ULTRABOX / month
            </ItemPool>

            <ItemPool style={{ marginLeft: 33, width: 90 }} className="hide">
              1.339.000 $
            </ItemPool> 

            <ItemPool style={{ marginLeft: 73}}>
              30 Days
            </ItemPool>
          </ItemBoxPool>
        </Link> */}

        <Link to={`/`}>
          <ItemBoxPool>
            <ItemPool style={{ width: 15 }} className="hide">
              FINISHED
            </ItemPool>

            <ItemPool style={{ marginLeft: 60, width: 85 }} className="hide">
              <img src={ETH} alt="ULTRABOX" style={{ width: 37 }} /> 
            </ItemPool>

            <ItemPool style={{ marginLeft: 55 }}>
              -
            </ItemPool>

            {/* <ItemPool style={{ marginLeft: 68, width: 120 }}>
              11800 ULTRABOX / month
            </ItemPool>

            <ItemPool style={{ marginLeft: 33, width: 90 }} className="hide">
              1.339.000 $
            </ItemPool> */}

            <ItemPool style={{ marginLeft: 73}}>
              -
            </ItemPool>
          </ItemBoxPool>
        </Link>

      </StyledCards>
    </Wrapper>
    
  )
}

const StyledCards = styled.div`
 a {
    text-decoration: none;
  }

`

const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  background: #000;
  height: 70px;
  min-width: 845px;
  border-radius: 5px 5px 0 0;
  color: #fff;
  font-weight: bold;
  padding: 0 10px;
  /* border: 2px solid #000; */

  @media(min-width: 1500px) {
    margin-top: 200px;
  }

  @media(max-width: 600px) {
    min-width: 0px;
    width: 310px;
  }

`

const ItemBox = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  color: #fff;
  min-width: 50px;
  font-size: 13px;
`

const ItemBoxPool = styled.div`
  display: flex;
  justify-content: space-between;

  height: 90px;
  min-width: 845px;
  max-width: 845px;
  color: #fff;
  background: #242424;
  padding: 0 10px;
  border: 2px solid #000000;
  border-top: none;
  cursor: pointer;

  .disable{
    color: #aaaaaa !important;
  }

  &:hover {
    background: #1d1d1d;
  }

  @media(max-width: 600px) {
    min-width: 0px;
    width: 310px;
    justify-content: space-between;

    .disable-mobile{
      color: #aaaaaa !important;
    }
  }
`

const ItemPool = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: #222; */
  min-width: 60px;
  font-size: 13px;

  .disable{
    color: #aaaaaa !important;
  }

  @media(max-width: 600px) {
    margin-left: 0px !important;
    width: auto !important;

  }
`

const Wrapper = styled.div`
  margin-bottom: 150px;

  @media(max-width: 800px) {
    margin: 0 10px;
    margin-bottom: 150px;
    margin-top: 150px;

    width: 330px;
    /* overflow: auto; */

    .hide {
      display: none;
    }
  } 
`

export default FarmCards
