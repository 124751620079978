
export const SUBTRACT_GAS_LIMIT = 100000

export const networkId = 11221

export const contractAddresses = {
  remit: {
    11221: '0x654BdCDBCDf4Af75E7703eDee49752B634198d0F',
  },
  weth: {
    11221: '0x654BdCDBCDf4Af75E7703eDee49752B634198d0F',
  },
  remitStake: {
    11221: '0x654BdCDBCDf4Af75E7703eDee49752B634198d0F',
  },
  remitVaultFarmEth: {
    11221: '0x654BdCDBCDf4Af75E7703eDee49752B634198d0F',
  },
  stake10: {
    11221: '0xCF5d682Cb22196b7056BcC855BE567113aFD9Ea5',
  },
}

export const supportedPools = []

export const farmingPools = { 
  remitETH: {
    11221: '0x654BdCDBCDf4Af75E7703eDee49752B634198d0F',
  }
}

