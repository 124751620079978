// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useCallback, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { UseWalletProvider } from 'use-wallet'
import MobileMenu from './components/MobileMenu'
import TopBar from './components/TopBar'
import FarmsProvider from './contexts/Farms'
import ModalsProvider from './contexts/Modals'
import TransactionProvider from './contexts/Transactions'
import RemitProvider from './contexts/RemitProvider'

import theme from './theme'
import Stake from "./views/Stake";
import Stake10 from "./views/Stake/Stake10";
import Farms from "./views/Farms";

const App: React.FC = () => {
  const [mobileMenu, setMobileMenu] = useState(false)

  const handleDismissMobileMenu = useCallback(() => {
    setMobileMenu(false)
  }, [setMobileMenu])

  const handlePresentMobileMenu = useCallback(() => {
    setMobileMenu(true)
  }, [setMobileMenu])

  return (
    <Providers>
      <Router>
        <TopBar onPresentMobileMenu={handlePresentMobileMenu} />
        <MobileMenu onDismiss={handleDismissMobileMenu} visible={mobileMenu} />
        <Switch>
         
          {/* <Route path="/farms">
            <Farms />
          </Route> */}
          {/* <Route path="/about">
            <About />
          </Route> */}

          <Route path="/" exact>
            {/* <Home /> */}
            <Farms />
          </Route>

          {/* <Route path="/stake">
            <Stake />
          </Route> */}
          
          {/* <Route path="/stake10">
            <Stake10 />
          </Route>
           */}
        </Switch>
      </Router>
      {/* <Disclaimer /> */}
    </Providers>
  )
}

const Providers: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <UseWalletProvider
        chainId={11221}
        connectors={{
          walletconnect: { rpcUrl: 'https://rpc.shinescan.io/' },
          // walletconnect: { rpcUrl: 'http://127.0.0.1:7545/' },
        }}
      >
        <RemitProvider>
          <TransactionProvider>
            <FarmsProvider>
              <ModalsProvider>{children}</ModalsProvider>
            </FarmsProvider>
          </TransactionProvider>
        </RemitProvider>
      </UseWalletProvider>
    </ThemeProvider>
  )
}

export default App
